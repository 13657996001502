
import 'superagent';
import './bootstrap';
import {lazyModule, ui} from './components/moduleHandler';
// import "../style/app.scss";

window.glob = {
    isLoggedIn: false
};

ui.glob = ({data}) => window.glob = {...window.glob, ...data};

ui.modal = lazyModule(() => import("./modules/modal"), 500);
ui.nav = lazyModule(() => import("./modules/nav"), 1000);
ui.consentBox = lazyModule(() => import("./modules/consentBox"));

ui.home = lazyModule(() => import("./modules/home"))

ui.auction = lazyModule(() => import("./modules/auction"));

ui.filter = lazyModule(() => import("./modules/filter"));

ui.product = lazyModule(() => import("./modules/product"));

ui.cart = lazyModule(() => import("./modules/cart"));

ui.registerForm = lazyModule(() => import("./modules/registerForm"));
ui.itemsLayout = lazyModule(() => import("./modules/itemsLayout"));
ui.giftCard = lazyModule(() => import("./modules/giftCard"));
ui.relatedSticky = lazyModule(() => import("./modules/relatedSticky"));
ui.membershipLevels = lazyModule(() => import("./modules/membershipLevels"));
ui.docSlider = lazyModule(() => import("./modules/docSlider"));
ui.slidingItems = lazyModule(() => import("./modules/slidingItems"), 1000);
ui.imageBox = lazyModule(() => import("./modules/imageBox"), 1000);
ui.infiniteScroll = lazyModule(() => import("./modules/infiniteScroll"));
ui.artists = lazyModule(() => import("./modules/artists"));
ui.articles = lazyModule(() => import("./modules/articles"));
ui.tv = lazyModule(() => import("./modules/tv"));


// asyncApi.then(({setHtml, setComponent, open}) => {
//
//     console.log(open);
//     setComponent({
//         Component: Login
//     })
//
//     open();
// })
